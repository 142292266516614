import React from "react"
import EventDetails from "../../components/EventsPage"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const event = {
  title: "[CLOSED] The Future of Work: Practitioner's Perspective",
  category: "Webinar",
  partner: null,
  location: "Zoom Meeting",
  date: "19 Desember 2020",
  price: "Gratis",
  slug: "the-future-of-work",
  thumb: "https://img.youtube.com/vi/W0WRJ7KL8K8/maxresdefault.jpg",
  url: "https://youtu.be/W0WRJ7KL8K8",
}

const EventPage = () => {
  return (
    <Layout>
      <SEO title={event.title} />
      <EventDetails event={event} />
    </Layout>
  )
}

export default EventPage
